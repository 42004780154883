<template>
    <WrapperVisualization
        :sidebarData="sidebarData"
        :selectedModuleId="selectedModuleId"
        :setModuleId="setModuleId"
        :widget="widget"
        @onChangeLevelClearance="value => levelClearance = value"
    >
        <template #header>
            <HeaderComponent
                :layouts="layouts"
                :selectedLayoutId="selectedLayoutId"
                :setLayoutId="setLayoutId"
                :widgets="widgets"
                :selectedWidgetId="selectedWidgetId"
                :setWidgetType="setWidgetType"
                :scenario="scenario"
            >
            </HeaderComponent>
        </template>
        <div v-if="selectedModuleId" class="flex w-full overflow-x-auto items-start">
            <SystemContent
                :module="selectedModule"
                :levelClearance="levelClearance"
                class="mt-20"
            />
            <component
                class="mt-8"
                v-if="'id' in widget"
                :is="getWidgetComponentByType(selectedWidgetType)"
                :filteredCollections="getFilteredCollections(selectedModule)"
                :flatData="getFilteredCollections(selectedModule)"
                :module="selectedModule"
                :widget="widget"
            ></component>
        </div>
        
    </WrapperVisualization>
</template>

<script setup>
    import { ref } from 'vue';
    import SystemContent from '@/components/shared/visualization/systemContent/Index'
    import WrapperVisualization from '@/elements/layout/WrapperVisualization'
    import HeaderComponent from '@/components/roadmap/visualization/Header.vue'
    import useRoadmap from '@/composable/useRoadmap'
    import useModule from '@/composable/useModule'
    import useWidgetVisualization from '@/composable/useWidgetVisualization';

    const { getWidgetComponentByType, getFilteredCollections } = useWidgetVisualization()
    const {  sidebarData } = useModule();
    const { 
        getModules,
        activeRoadmapData,
        selectedModuleId,
        selectedModule,
        setModuleId,
        layouts,
        selectedLayoutId,
        selectedWidgetId,
        widgets,
        widget,
        setLayoutId,
        selectedWidgetType,
        setWidgetType,
        scenario,
        filteredData
    } = useRoadmap(null, null, {
        components: [
            'visualization',
            'Sidebar'
        ]
    })

    const levelClearance = ref(4);
</script>