import { ref, onMounted, computed } from 'vue'
import store from '@/store';
import router from '@/routes/routes.js';

export default function useRoadmap() {
    // Declaration
    const route = router.currentRoute;
    const selectedModuleId = ref(null);
    const selectedLayoutId = ref(null);
    const selectedWidgetType = ref(null);
    const selectedWidgetId = ref(null);
    const widget = ref({});

    // Computed
    const activeRoadmapData = computed(() => store.getters['roadmap/programmatic']);
   
    const selectedModule = computed(() => {
        const moduleId = selectedModuleId.value;
        if (!activeRoadmapData.value) {
            return {};
        }
        selectedLayoutId.value = null
        selectedWidgetId.value = null
        selectedWidgetType.value = null
        return activeRoadmapData.value.modules?.find(module => module.id == moduleId)
    })

    const scenario = computed(() => {
        const roadmapData = activeRoadmapData.value
        if (roadmapData && 'scenario' in roadmapData) {
            return roadmapData.scenario
        }
    })


    const layouts = computed(() => {
        const roadmapData = activeRoadmapData.value
        let module = selectedModule.value
        if (roadmapData && 'modules' in roadmapData && module) {
            let moduleData = roadmapData.modules.find(item => item.id == module.id)
            return moduleData ? moduleData.layouts : []
        }
    })

    const widgets = computed(() => {
        const localLayouts = layouts.value
        const layoutId = selectedLayoutId.value
        if (localLayouts && layoutId) {
            let layout = localLayouts.find(item => item.id == layoutId)
            return layout ? layout.widgets : []
        }
    });


    // Methods
    const setLayoutId = (id) => selectedLayoutId.value = id

    const setWidgetType = (widgetId) => {
        if (!widgets.value || !widgetId) {
            return
        }
        let selectedWidget = widgets.value.find(({ id }) => id == widgetId)
        selectedWidgetType.value = selectedWidget.type;
        selectedWidgetId.value = selectedWidget.id;
        widget.value = selectedWidget
    }
    const setFirstModuleId = (data) => selectedModuleId.value = data[0].id
    const setModuleId = (id) => selectedModuleId.value = id;

    const getModules = () => {
        return activeRoadmapData.value.modules ? activeRoadmapData.value.modules : []
    }


    // Lifecycle methods
    onMounted(async () => {
        let { data: {data} } = await store.dispatch("roadmap/getFirstModuleProgrammatic", { roadmap_id: route.params.roadmapId, from_page: true });
        setFirstModuleId(data.modules)
        store.dispatch('definition/getDefinitionSets', {project_id: route.params.projectId})
    })


    return {
        getModules,
        activeRoadmapData,
        selectedModuleId,
        selectedModule,
        setModuleId,
        layouts,
        selectedLayoutId,
        widgets,
        widget,
        setLayoutId,
        selectedWidgetType,
        selectedWidgetId,
        setWidgetType,
        scenario
    }
}