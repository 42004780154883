import { ref, computed, onMounted} from 'vue'
import Collection from '@/store/services/traits/collection'
import store from '@/store';
import router from '@/routes/routes.js';
import { find, get } from 'lodash';
export default function uesModule() {
    // const activeRoadmapData = computed(() => store.getters['roadmap/programmatic']);
    const modules = ref(null);
    const collection = new Collection;
    const route = router.currentRoute;
    const sidebarData = ref([])
    const allModule = ref([]) // it can be modules just a plural form
   

    const makeSidebarData = (modules) => {
        if(modules.length) {
            sidebarData.value = modules.map(module => ({
                id: module.id,
                color: collection.getModuleBg(module,1, store.getters['global/colorCodes']) 
            }))
            return
        }
        sidebarData.value = []
    }

    const getBackground = (module) => {
        if(!collection || !module ) {
            return '';
        }
       return collection.getModuleBg(module, 1, store.getters['global/colorCodes']); 
    }

    const getIndexCode = (module, moduleIndex = 0, layoutIndex = -1) => {
        let collectionSettings = JSON.parse(module.collection_settings);
        if(collectionSettings.collections[0] && collectionSettings.collections[0].index_number) {
          if(layoutIndex < 0) {
            return collectionSettings.collections[0].index_number;
          }
          return `${(moduleIndex + 1) + '.' + (layoutIndex + 1)}`;
        }
        return null;
    }
    const prepareModuleProperties = (module, collections) => {
        module.properties = [];
        let properties = module.system_properties.map((property) => property.property_collections);
        if (properties.length > 0) {
          let parameters = [];
          properties.forEach((item) => {
            parameters = [...parameters, ...item.map((data) => data.id)];
          });
      
          if (parameters && parameters.length > 0) {
            parameters.forEach((param) => {
              let parameterSampleValue = 0;
              collections.forEach((item) => {
                let paramObj = item.properties?.find(
                  (paramValue) => paramValue.property_collection_id === param
                );
                if (paramObj) {
                  parameterSampleValue += paramObj.sample_value;
                }
              });
              module.properties.push({
                module_id: module.id,
                property_collection_id: param,
                sample_value: parameterSampleValue / collections.length,
              });
            });
          }
        }
        return module;
    }
    const getModuleIdFromDataLayer = (template, confId = 'data_source') => {
        let persistance = get(find(template.data_layer, ['conf_id', confId]), 'persistance');
        return parseInt(get(persistance, 'module_id', 0));
    }
    onMounted(async () => {
        let { data } = await store.dispatch('programmatic/getModules', route.params.projectId);
        makeSidebarData(data)
        allModule.value = data
    });

    

    return {
        sidebarData,
        prepareModuleProperties,
        getModuleIdFromDataLayer,
        getIndexCode,
        getBackground
    }
}