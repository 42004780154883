<template>
    <div class="flex gap-12 font-poppins">
        <div class="flex items-center text-primary-one">
            <span class="fw-600 fs-14">Active Scenario:</span>
            <h4 class="fs-400 fw-400 ml-2">{{ scenario ? scenario.title : '' }}</h4>
<!--            <select v-model="scenarioId" class="bg-transparent focus:outline-primary-six">-->
<!--                <option v-if="scenario" class="fs-400 fw-400" :value="null" disabled >{{ scenario.title }}</option>-->
<!--            </select>-->
        </div>
        <div class="flex items-center text-primary-one">
            <span class="fw-600 fs-14">Data Source:</span>
            <select v-model="propertyId" class="bg-transparent focus:outline-primary-six">
                <option class="fs-400 fw-400" :value="null" disabled >Scenario Properties</option>
                <option class="fs-400 fw-400" :value="null" disabled >Assessment Data</option>
            </select>
        </div>

        <div class="flex items-center text-primary-one">
            <span class="fw-600 fs-14">Layout:</span>
            <select v-model="layoutID" class="bg-transparent focus:outline-primary-six">
                <option class="fs-400 fw-400" :value="null" disabled >Select Layout</option>
                <option
                    v-for="(layout, layoutIndex) in layouts"
                    :key="layoutIndex"
                    :value="layout.id"
                >
                    {{ layout.title }}
                </option>
            </select>
        </div>

        <div class="flex items-center text-primary-one">
            <span class="fw-600 fs-14">Widget:</span>
            <select v-model="widgetId" class="bg-transparent focus:outline-primary-six">
                <option class="fs-400 fw-400" :value="null" disabled >Select widget</option>
                <option
                    v-for="(widget, widgetIndex) in widgets"
                    :key="widgetIndex"
                    :value="widget.id"
                >
                    <!-- {{ getWidgetNameByType(widget.type) }} -->
                    {{ widget.title }}
                </option>
            </select>
        </div>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import useWidgetVisualization from '@/composable/useWidgetVisualization'
// import store from '@/store';
// eslint-disable-next-line no-undef
const props = defineProps({
  layouts: Array,
  selectedLayoutId: Number,
  setLayoutId: Function,
  widgets: Array,
  selectedWidgetId: Number,
  setWidgetType: Function,
  scenario: Object
});

const { getWidgetNameByType } = useWidgetVisualization()

const scenarioId = ref(null)
const propertyId = ref(null)

const layoutID = computed({
    get() {
        return props.selectedLayoutId
    },
    set(val) {
        props.setLayoutId(val)
    }
})

// const project = computed(() => {
//   return store.getters['project/project']
// })

const widgetId = computed({
    get() {
        return props.selectedWidgetId
    },
    set(val) {
        props.setWidgetType(val)
    }
})
</script>
